import { gql } from "@apollo/client";

export const GET_THP_LIST = gql`
  query GetTHP_list {
    thp_list {
      name
      activated_at
      expiry
      yearly
      invoice_credits
      seat_credits
      brand_credits
      brd_lists {
        id
        name
        brd_unique_inqs {
          main_inq {
            created_at
            status
          }
        }
        acc_transactions {
          created_at
          amount
          type
          def_acc_list {
            type
          }
        }
      }
      def_subscription_plan {
        plan_name
      }
    }
  }
`;

export const GET_TRAVEL_HOUSE_DETAIL = gql`
  query GetTravelHouse($user_id: uuid!, $custom_url: String!) {
    thp_list(
      where: {
        _or: [
          { domain: { _eq: $custom_url } }
          { subdomain: { _eq: $custom_url } }
        ]
        _and: {
          _or: [
            { brd_users: { users: { id: { _eq: $user_id } } } }
            { primary_user: { id: { _eq: $user_id } } }
          ]
        }
      }
    ) {
      active_brand
      id
      yearly
      plan_id
      invoice_credits
      subscription_user_ref
      subscription_ref
      def_subscription_plan {
        metadata
        monthly_plan_invoices
        monthly_plan_seats
        monthly_plan_brands
        yearly_plan_invoices
        yearly_plan_brands
        yearly_plan_seats
      }
    }
  }
`;

export const GET_TRAVEL_HOUSE_DETAIL_WITH_SUB = gql`
  subscription GetTravelHouse($user_id: uuid!, $custom_url: String!) {
    thp_list(
      where: {
        _or: [
          { domain: { _eq: $custom_url } }
          { subdomain: { _eq: $custom_url } }
        ]
        _and: {
          _or: [
            { brd_users: { users: { id: { _eq: $user_id } } } }
            { primary_user: { id: { _eq: $user_id } } }
          ]
        }
      }
    ) {
      id
      yearly
      plan_id
      invoice_credits
      subscription_user_ref
      subscription_ref
      invoice_credits_buy
      active_brand
      brd_lists {
        id
      }
      brd_users {
        users {
          defaultRole
        }
      }
      def_subscription_plan {
        metadata
        monthly_plan_invoices
        monthly_plan_seats
        monthly_plan_brands
        yearly_plan_invoices
        yearly_plan_brands
        yearly_plan_seats
      }
    }
  }
`;
