import { Autocomplete, TextField } from "@mui/material";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useEffect, useState } from "react";
import { useUserId } from "@nhost/react";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { useQuery } from "@apollo/client";
import { GET_BRD_WITH_TYPES } from "~/graphql/brd_list/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

const Brandfilter = ({}: any) => {
  const { brands: selectedBrands, setBrands }: any = brandsStore();

  const { travelHouse }: any = travelHouseStore();

  const { data: brdList } = useQuery(GET_BRD_WITH_TYPES, {
    variables: {
      thpId: travelHouse?.id,
      brdTypeId: travelHouse?.active_brand,
    },
    fetchPolicy: "network-only",
  });

  function getUniqueById(arr: any) {
    // Create a Map to store objects by their unique 'id' property
    const uniqueMap = new Map();

    // Iterate over each object in the array
    arr.forEach((obj: any) => {
      // Add each object to the Map with its 'id' as the key
      // This automatically overwrites any duplicate 'id' with the latest object
      uniqueMap.set(obj.id, obj);
    });

    // Convert the Map values back to an array
    return Array.from(uniqueMap.values());
  }

  useEffect(() => {
    if (selectedBrands.length < 1 && brdList) {
      setBrands(brdList?.brd_list);
    }
  }, [brdList]);

  const handleFlagChange = (event: any, newValue: any) => {
    const renameArr = newValue.map((item: any) => ({
      id: item.id,
      name: item.name,
      phone: item.phoneNumberId,
    }));

    const uniqueArray = getUniqueById(renameArr);
    setBrands(uniqueArray);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={brdList?.brd_list || []}
        multiple
        sx={{
          width: "100%",
          // padding: "4px", // Apply padding to the Autocomplete component
          "& .MuiInputBase-root": {
            padding: "5px", // Apply padding to the input area inside Autocomplete
          },
        }}
        onChange={handleFlagChange}
        value={selectedBrands}
        getOptionLabel={(option: any) => option?.name}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
};

export default Brandfilter;
