import React, { useState } from "react";
import { URLs } from "~/config/enums";
import BrandEdit from "../BrandEdit";
import { BsThreeDots } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import { Menu } from "@mantine/core";
import { NavLink } from "react-router-dom";
import { TbFaceIdError } from "react-icons/tb";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_BRAND } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useClickOutside } from "@mantine/hooks";
const BrandCard = ({ brand }: any) => {
  const [edit, setEdit] = useState(false);
  const [deleteBrd] = useMutation(DELETE_BRAND);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useClickOutside(() => setShowMenu(false));
  const client = useApolloClient();
  if (edit) return <BrandEdit setEdit={setEdit} data={brand} />;

  const deleteBrand = async (id: any) => {
    const res = await deleteBrd({
      variables: {
        id: id,
      },
    });

    if (res?.data?.delete_brd_list_by_pk?.id) {
      toast.success("Brand  deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  console.log("shfdsf", brand);

  return (
    <div className="w-full md:max-w-sm mx-auto bg-secondary dark:bg-dark-secondary rounded-lg shadow  ">
      <div className="flex justify-end px-4 pt-4 relative">
        <div className="menu relative" ref={menuRef}>
          <button
            className="text-primary dark:text-dark-primary"
            onClick={() => setShowMenu(true)}
          >
            <BsThreeDots />
          </button>
          {showMenu && (
            <ul className="nav rounded-lg shadow absolute right-0 z-[2] min-w-[200px] text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary">
              {brand?.brand_type === "8091f67a-589b-4ac3-93f0-658e8e0ce967" ? (
                <>
                  <li>
                    <NavLink
                      className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                      to={`/admin/brands/${brand.id}/universities`}
                    >
                      Universities
                    </NavLink>
                  </li>
                </>
              ) : (
                brand?.brand_type ===
                  "43797e0f-75ab-4629-ad24-3d74037fc3ae" && (
                  <>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/edit`}
                      >
                        Edit Brand
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/faqs`}
                      >
                        Manage FAQs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/testimonials`}
                      >
                        Manage Testimonials
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/page/home`}
                      >
                        Home Page
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/page/privacy-policy`}
                      >
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/page/terms-and-conditions`}
                      >
                        Terms & Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/page/flights`}
                      >
                        Flights List Page
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/import-flight-pages`}
                      >
                        Import Flights Pages
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/page/airlines`}
                      >
                        Airlines
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/whatsapp`}
                      >
                        Whatsapp Messages
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/email-setting`}
                      >
                        Email Settings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/social-connect`}
                      >
                        Social Connect
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/email-templates`}
                      >
                        Email Templates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/in-app-alerts-templates`}
                      >
                        In-app Alerts Templates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/whatsapp-templates`}
                      >
                        Whatsapp Templates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/brdSeasonalFares`}
                      >
                        Seasonal Fares
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary transition-all block text-[14px]`}
                        to={`/admin/brands/${brand.id}/auto-bot-templates`}
                      >
                        Auto Bot Messages
                      </NavLink>
                    </li>
                  </>
                )
              )}
              <li>
                <button
                  onClick={() => deleteBrand(brand.id)}
                  className={`p-3 py-[10px] rounded dark:hover:bg-dark-primary hover:bg-primary w-full text-left text-[14px] transition-all block text-red-500`}
                >
                  Delete Brand
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center pb-10">
        {brand?.brd_details[0]?.fav_icon ? (
          <img
            className="w-24 h-24 mb-3 rounded-full shadow-lg bg-basic"
            src={URLs.FILE_URL + brand?.brd_details[0]?.fav_icon}
            alt="Icon"
          />
        ) : brand?.brd_details[0]?.logo ? (
          <img
            className="w-24 h-24 mb-3 rounded-full shadow-lg bg-basic"
            src={URLs.FILE_URL + brand?.brd_details[0]?.logo}
            alt="Icon"
          />
        ) : (
          <div className="w-24 h-24 mb-3 rounded-full shadow-lg flex items-center justify-center">
            <TbFaceIdError className="text-[40px] text-primary dark:text-dark-primary" />
          </div>
        )}
        <h5 className="mb-1 text-xl font-medium text-primary dark:text-dark-primary ">
          {brand?.name}
        </h5>
        {brand?.domain && (
          <a href={`https://${brand?.domain}`} target="_blank">
            <span className="text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1">
              {brand?.domain} <BiLinkExternal />
            </span>
          </a>
        )}
        {brand?.subdomain && (
          <a href={`https://${brand?.subdomain}`} target="_blank">
            <span className="text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1">
              {`${brand?.subdomain}`} <BiLinkExternal />
            </span>
          </a>
        )}
        <div className="flex mt-4 flex-col items-center gap-[5px] md:mt-6">
          <NavLink
            className="inline-flex mx-auto items-center px-4 py-2 text-sm font-medium text-center text-white bg-basic rounded-lg focus:ring-4 focus:outline-none "
            to={`/admin/brands/${brand.id}/destinations`}
          >
            Destination Pages
          </NavLink>
          <NavLink
            className="inline-flex mx-auto items-center px-4 py-2 text-sm font-medium text-center text-white bg-basic rounded-lg focus:ring-4 focus:outline-none "
            to={`/admin/brands/${brand.id}/airlines`}
          >
            Airline Pages
          </NavLink>
          <NavLink
            className="inline-flex mx-auto items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100"
            to={`/admin/brands/${brand.id}/posts`}
          >
            Blog Posts
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
