import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import countries from "../../../../../../components/FormInputs/CountryBox/data/countries.json";
import PhoneInput from "react-phone-input-2";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_DEF_EMAIL_TEMP } from "../../../InsertBrand/components/InsertBrandGeneralInfo/graphql/Query";
import { useNavigate } from "react-router-dom";
import {
  ADD_SUB_DOMAIN_VERCEL_CLOUDFLARE,
  INSERT_BRAND,
} from "../../../InsertBrand/components/InsertBrandGeneralInfo/graphql/Mutation";
import { toast } from "react-toastify";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { GET_BRAND_TYPES } from "~/graphql/def_brand_types/Query";

const InsertBrandInformation = () => {
  const { travelHouse }: any = travelHouseStore();
  const { loading, error, data } = useQuery(GET_DEF_EMAIL_TEMP);
  const navigate = useNavigate();
  const [insertBrand] = useMutation(INSERT_BRAND);
  const client = useApolloClient();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  // getting brands types from query
  const { data: brandData } = useQuery(GET_BRAND_TYPES);
  const customUrl = GetCustomUrl(window.location.host);

  const { thpHouse } = useThpListDetail(customUrl);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const [subDomain, setSubDomain] = useState("");

  const onSubmit = async (inpData: any) => {
    console.log("dataadfasdf", inpData);
    let emailTemplate: any = [];
    data.def_emails_templates.map((temp: any) => {
      const tempObj = {
        template_id: temp.id,
        subject: temp.subject,
        content: temp.content,
      };
      emailTemplate.push(tempObj);
    });
    const res = await insertBrand({
      variables: {
        data: {
          name: inpData?.brandName,
          thp_id: travelHouse.id,
          subdomain: inpData?.subDomain + ".flygency.com",
          // brd_allowed_types: {
          //   data: inpData?.brandType?.map((item: any) => ({
          //     brand_type_id: item,
          //   })),
          // },
          brand_type: inpData?.brandType,
          brd_emails_templates: {
            data: emailTemplate,
          },
          brd_contact_details: {
            data: {
              email: inpData?.email,
              phone: inpData?.phoneNumber,
              city: inpData?.city,
              country: inpData?.country,
              whatsapp: inpData?.whatsappNumber,
              address1: inpData?.address,
            },
          },
          brd_pages: {
            data: [
              {
                name: "Home",
                page_sub_heading: "The Excitement of Adventure",
                slug: "/",
                title: "Flygency",
              },
              {
                name: "Privacy Policy",
                page_sub_heading: "Privacy Policy settings",
                slug: "privacy-policy",
                title: "Title",
              },
              {
                name: "Flights",
                page_sub_heading: "Flights settings",
                slug: "flights",
                title: "Title",
              },
              {
                name: "Terms of Use",
                page_sub_heading: "Terms",
                slug: "terms-and-conditions",
                title: "Title",
              },
            ],
          },
        },
      },
    });
    const responseADDDomain = await client.query({
      query: ADD_SUB_DOMAIN_VERCEL_CLOUDFLARE,
      variables: {
        subdomain: inpData?.subDomain + ".flygency.com",
      },
    });
    if (res?.data?.insert_brd_list_one?.id) {
      toast.success("brand updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      navigate(`/admin/brands/${res?.data?.insert_brd_list_one?.id}/edit`);
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <>
      <div className="md:w-[700px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          Insert Brand Information
        </h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <div className="md:col-span-1">
              <Controller
                control={control}
                {...register("brandName", { required: true })}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["brand"] && el.focus();
                    }}
                    error={errors["brandName"] ? true : false}
                    label="Brand Name"
                    type="text"
                    onChange={(event: any) => {
                      const brandName = event.target.value;
                      const getSubDomain = brandName
                        .replace(/\s+/g, "-")
                        .toLowerCase();
                      field.onChange(brandName);
                      setSubDomain(getSubDomain);
                      setValue("subDomain", getSubDomain);
                    }}
                  />
                )}
              />
            </div>
            {/* <div className="md:col-span-1">
              <Controller
                control={control}
                name="brandType"
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    inputRef={(el) => {
                      field.ref(el);
                      if (el && errors?.brandType) el.focus();
                    }}
                    error={!!errors?.brandType}
                    label="Brand Type"
                    type="text"
                    onChange={(event: any) => {
                      const brandType = event.target.value;
                      field.onChange(brandType);
                    }}
                  />
                )}
              />
            </div> */}
            {/* <div className="md:col-span-1">
              <Controller
                control={control}
                name="brandType"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors?.brandType}>
                    <InputLabel id="brandType-label">
                      Consultancy Type
                    </InputLabel>
                    <Select
                      labelId="brandType-label"
                      label="Consultancy Type"
                      multiple
                      value={field.value || []}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                    >
                      <MenuItem value="traveling">
                        <Checkbox
                          checked={
                            field.value?.indexOf("travelling_consultancy") > -1
                          }
                        />
                        <ListItemText primary="Traveling Consultancy" />
                      </MenuItem>
                      <MenuItem value="educational">
                        <Checkbox
                          checked={
                            field.value?.indexOf("educational_consultancy") > -1
                          }
                        />
                        <ListItemText primary="Educational Consultancy" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div> */}
            <div className="md:col-span-1">
              <Controller
                control={control}
                name="brandType"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors?.brandType}>
                    <InputLabel id="brandType-label">
                      Consultancy Type
                    </InputLabel>
                    <Select
                      labelId="brandType-label"
                      label="Consultancy Type"
                      value={field.value || ""}
                      renderValue={(selected) =>
                        brandData?.def_brand_types.find(
                          (bt: any) => bt.id === selected
                        )?.name || ""
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        console.log(event.target.value);
                      }}
                    >
                      {brandData?.def_brand_types?.map(({ id, name }: any) => (
                        <MenuItem key={id} value={id}>
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <TextField
              error={
                errors["subDomain"] && subDomain?.length < 1 ? true : false
              }
              {...register("subDomain", { required: true })}
              label="Sub Domain"
              type="text"
              value={subDomain}
            />
            <TextField value=".flygency.com" disabled />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("country", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  filterOptions={(options, { inputValue }: any) => {
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option: any) =>
                      option?.label
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  onChange={(_, data: any) => {
                    field.onChange(data?.label);
                  }}
                  options={countries ? countries : []}
                  getOptionLabel={(option: any) => option?.label}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Country"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["country"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              error={errors["city"] ? true : false}
              {...register("city", { required: true })}
              label="City"
              type="text"
            />
            <TextField
              error={errors["address"] ? true : false}
              {...register("address", { required: true })}
              label="Address"
              type="text"
            />
            <TextField
              error={errors["email"] ? true : false}
              {...register("email", { required: true })}
              label="Email"
              type="email"
            />
            <div>
              <FormLabel>PhoneNumber</FormLabel>
              <Controller
                control={control}
                {...register("phoneNumber")}
                render={({ field, fieldState }: any) => (
                  <PhoneInput
                    {...field}
                    inputProps={{
                      className:
                        "w-full py-[16px] px-[14px] pl-[50px] bg-transparent border-gray-400 dark:border-gray-600 hover:border-gray-700 dark:hover:border-gray-200 rounded-[3px] focus:border-basic focus:ring-basic",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    dropdownStyle={
                      darkMode
                        ? {
                            backgroundColor: "#312D4B",
                            borderRadius: "3px",
                            color: "#9155FD",
                          }
                        : {
                            backgroundColor: "#F4F5FA",
                            borderRadius: "3px",
                            color: "#3A3541DE",
                          }
                    }
                    country={"gb"} // Set your desired default country
                    enableAreaCodes={true} // Show countries dropdown
                  />
                )}
              />
            </div>
            <div>
              <FormLabel>Whatsapp</FormLabel>
              <Controller
                control={control}
                {...register("whatsappNumber")}
                render={({ field, fieldState }: any) => (
                  <PhoneInput
                    {...field}
                    inputProps={{
                      className:
                        "w-full py-[16px] px-[14px] pl-[50px] bg-transparent border-gray-400 dark:border-gray-600 hover:border-gray-700 dark:hover:border-gray-200 rounded-[3px] focus:border-basic focus:ring-basic",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    dropdownStyle={
                      darkMode
                        ? {
                            backgroundColor: "#312D4B",
                            borderRadius: "3px",
                            color: "#9155FD",
                          }
                        : {
                            backgroundColor: "#F4F5FA",
                            borderRadius: "3px",
                            color: "#3A3541DE",
                          }
                    }
                    country={"gb"} // Set your desired default country
                    enableAreaCodes={true} // Show countries dropdown
                  />
                )}
              />
            </div>
          </div>
          {(thpHouse?.[0]?.yearly === false &&
            thpHouse?.[0]?.brd_lists?.length <
              thpHouse?.[0]?.def_subscription_plan?.monthly_plan_brands) ||
          (thpHouse?.[0]?.yearly === true &&
            thpHouse?.[0]?.brd_lists?.length <
              thpHouse?.[0]?.def_subscription_plan?.yearly_plan_brands) ? (
            <Button
              type="submit"
              sx={{ width: "100%", marginTop: "25px" }}
              variant="contained"
            >
              Add
            </Button>
          ) : (
            <p className="text-red-500 font-bold mt-6 text-center">
              You have used all your brand credits
            </p>
          )}
        </Box>
      </div>
    </>
  );
};

export default InsertBrandInformation;
